<template>
  <b-modal
    id="banner-modal"
    @hidden="
      $emit('resetModal');
      bannerCampaigns = [];
    "
    :title="selectedBanner ? 'Update Banner' : 'Add Banner'"
    hide-footer
  >
    <FormulateForm
      name="BannerAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <multiselect
        :multiple="true"
        label="name"
        v-model="bannerCampaigns"
        :max="40"
        track-by="value"
        :maxHeight="400"
        :searchable="true"
        placeholder="Search campaign"
        :options="
          selectCampaignOptions.map((item) => {
            return {
              name: 'Company: ' + item.company.name,
              value: item._id,
              data: item,
            };
          })
        "
      ></multiselect>
      <FormulateInput
        type="image"
        name="bannerImage"
        v-model="bannerImage"
        label="Upload a cover image for your Banner"
        help="Select a png or jpg to upload."
        validation="required"
      />

      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedBanner"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedBanner"> Update </span>
          <span v-else> Add </span>
          Banner</span
        >
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { banner } from "@/config/api/banner";
import { getFormData } from "@/utils/createForm";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: ["selectedBanner", "selectCampaignOptions"],
  data() {
    return {
      bannerImage: null,
      bannerCampaigns: [],
      values: {},
      performAction: false,
    };
  },
  methods: {
    async submitHandler(data) {
      //       data.campaigns = [this.bannerCampaigns[0].value, this.bannerCampaigns[1].value]

      // data.campaigns = this.bannerCampaigns.map((item) => item.value)
      this.$delete(data, "bannerImage");
      let formdata = null;
      if (this.bannerImage) {
        formdata = getFormData({
          ...data,
          file: this.bannerImage.fileList[0],
          campaigns: this.bannerCampaigns.map((item) => item.value),
        });
      } else formdata = { ...data };
      if (this.selectedBanner) {
        this.updateBanner(formdata, this.selectedBanner._id);
      } else {
        this.addBanner(formdata);
      }
    },
    addBanner(data) {
      this.performAction = true;
      const api = banner.create;
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$formulate.reset("BannerAction");
          this.$bvToast.toast("Banner added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Banner is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateBanner(data, id) {
      this.performAction = true;
      const api = banner.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Banner updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Banner is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedBanner(val) {
      if (val) {
        this.values = { ...val };
      }
      if (this.selectedBanner) {
        this.bannerCampaigns = this.selectedBanner.campaigns.map((item) => {
          return { value: item, name: item.company.name };
        });
      }
    },
  },
  computed: {
    schema() {
      console.log(this.selectCampaignOptions);
      return [
        {
          index: 0,
          type: "number",
          name: "sort_order",
          label: "Sort Order",
          validation: "required",
          validationMessages: {
            required: "Unique sort value is required.",
          },
        },
        // {
        //   index: 1,
        //   type: "tags-input-search",
        //   validation: "required",
        //   name: "campaigns",
        //   options: this.selectCampaignOptions.map((item) => {
        //     return {
        //       name: "Sticker type: " + item.sticker_type + ", id: " + item._id,
        //       value:item._id,
        //       data: item
        //     }
        //   })
        // },
      ];
    },
  },
};
</script>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
.mb-0,
.dropdown-item {
  color: black !important;
}
</style>
