var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"banner-modal","title":_vm.selectedBanner ? 'Update Banner' : 'Add Banner',"hide-footer":""},on:{"hidden":function($event){_vm.$emit('resetModal');
    _vm.bannerCampaigns = [];}}},[_c('FormulateForm',{attrs:{"name":"BannerAction","schema":_vm.schema},on:{"submit":_vm.submitHandler},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}},[_c('multiselect',{attrs:{"multiple":true,"label":"name","max":40,"track-by":"value","maxHeight":400,"searchable":true,"placeholder":"Search campaign","options":_vm.selectCampaignOptions.map(function (item) {
          return {
            name: 'Company: ' + item.company.name,
            value: item._id,
            data: item,
          };
        })},model:{value:(_vm.bannerCampaigns),callback:function ($$v) {_vm.bannerCampaigns=$$v},expression:"bannerCampaigns"}}),_c('FormulateInput',{attrs:{"type":"image","name":"bannerImage","label":"Upload a cover image for your Banner","help":"Select a png or jpg to upload.","validation":"required"},model:{value:(_vm.bannerImage),callback:function ($$v) {_vm.bannerImage=$$v},expression:"bannerImage"}}),_c('FormulateInput',{staticClass:"float-right",attrs:{"type":"submit"}},[(_vm.performAction)?_c('span',[_c('b-spinner',{staticClass:"vueformulate-loader"}),(_vm.selectedBanner)?_c('span',[_vm._v(" Updating... ")]):_c('span',[_vm._v(" Adding... ")])],1):_c('span',[(_vm.selectedBanner)?_c('span',[_vm._v(" Update ")]):_c('span',[_vm._v(" Add ")]),_vm._v(" Banner")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }